<template>
    <sides-lip ref="SidesLip">
        <div slot="soltForm">
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
        <el-form-item label="属性值" prop="attrValueName">
          <el-input v-model="dataForm.attrValueName" placeholder="属性值"></el-input>
        </el-form-item>
        <el-form-item label="当前排序" prop="currentSort">
          <el-input v-model="dataForm.currentSort" placeholder="当前排序"></el-input>
        </el-form-item>
      </el-form>
      </div>
      <div slot="soltFooter">
        <el-button @click="sideClose">取消</el-button>
        <el-button type="primary" :loading="loading" @click="dataFormSubmit()">确定</el-button>
      </div>
    </sides-lip>
</template>

<script>
import { mallGoodsAttrValueList, findMallGoodsAttrValueInfo, mallGoodsAttrValueDataFormSubmit, delMallGoodsAttrValue} from "@/api/sys";
import SidesLip from "@/components/SidesLip";
export default {
  data() {
    return {
      loading: false,
      visible: false,
      dataForm: {
        id: 0,
        attrValueName: "",
        currentSort: ""
      },
      dataRule: {
        attrValueName: [
          { required: true, message: "属性值不能为空", trigger: "blur" }
        ]
      }
    };
  },
  components: {
    SidesLip
  },
  methods: {
    async init(id,mallGoodsAttrId) {
      this.dataForm.id = id || 0;
      this.dataForm.mallGoodsAttrId = mallGoodsAttrId;
      this.$refs.SidesLip.init(this.dataForm.id?"修改":"新增", "700px", "block");
      await this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
      });
      if (this.dataForm.id) {
        const { data } = await findMallGoodsAttrValueInfo(`/mall/mallgoodsattrvalue/info/${this.dataForm.id}`);
        if (data && data.code === 0) {
          this.dataForm.attrValueName = data.mallGoodsAttrValue.attrValueName;
          this.dataForm.currentSort = data.mallGoodsAttrValue.currentSort;
        }
      }
    },
    sideClose() {
      this.$refs.SidesLip.sideClose();
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid) {
          const params = {
            mallGoodsAttrValueId: this.dataForm.id || undefined,
            attrValueName: this.dataForm.attrValueName,
            currentSort: this.dataForm.currentSort,
            mallGoodsAttrId: this.dataForm.mallGoodsAttrId
          };
          const { data } = await mallGoodsAttrValueDataFormSubmit(
            `/mall/mallgoodsattrvalue/${!this.dataForm.id ? "save" : "update"}`,
            params
          );
          if (data && data.code === 0) {
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.sideClose();
                this.$emit("refreshDataList");
              }
            });
          } else {
            this.$message.error(data.msg);
          }
        }
      });
    }
  }
};
</script>
